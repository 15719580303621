import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequiredLabelDirective } from './directives/required-label.directive';
import { EscapeHtmlPipe } from './pipes/EscapeHtmlPipe';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        RequiredLabelDirective,
        EscapeHtmlPipe
    ],
    declarations: [],
    exports: [
        RequiredLabelDirective,
        EscapeHtmlPipe
    ],
    providers: []
})
export class SharedModule { }
