import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor(private authentication: AuthenticationService) {
    }

  ngOnInit() {
    console.log('inside header.component');
  }

  logout() {
      this.authentication.logout();
  }
}
