import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ServerModels } from '@app/core/models/ServerModels';
import { throwError, Observable } from 'rxjs';
import { retry, catchError, switchMap, map } from 'rxjs/operators';
import { DataService } from '@app/shared/services/data.service';
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OptionsService implements OnInit {
    public forcedRefresh: boolean = false;
    public closedOnly: boolean = true;
    public useLinksToIssueTracker: boolean = false;
	public allCodeSnippets: boolean = false;
    public selectedProject: ServerModels.Project;
    public selectedVersion: ServerModels.Version;
    public outputFormat: ServerModels.ReleaseNotesOutputFormat = ServerModels.ReleaseNotesOutputFormat.Markdown;

    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }

    constructor() {
    }
}
