import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { Routes, RouterModule, provideRouter } from '@angular/router';

// Material Standalone Components
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MatInput } from '@angular/material/input';

// Application Components
import { AppComponent } from '@app/app.component';
import { NotFoundComponent } from '@app/core/not-found/not-found.component';
import { SharedModule } from '@app/shared/shared.module';

// Services & Interceptors
import { AuthenticationService } from '@app/shared/services/authentication.service';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { ApiInterceptor } from '@app/shared/interceptors/api-interceptor.service';
import { RequestCacheService } from '@app/shared/services/request-cache.service';
import { CachingInterceptor } from '@app/shared/interceptors/caching.interceptor';
import { OptionsService } from '@app/release-notes/Services/options.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { enableProdMode } from '@angular/core';
import { environment } from '../environments/environment';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

if (environment.production) {
  enableProdMode();
}

export const routes: Routes = [
  { path: '', redirectTo: 'release-notes', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.loginRoutes) },
  { 
    path: 'release-notes', 
    canActivate: [AuthGuard], 
    loadChildren: () => import('./release-notes/release-notes.module').then(m => m.releaseNotesRoutes) 
  },
  { path: '**', component: NotFoundComponent }
];

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(),
    provideRouter(routes),
    importProvidersFrom(HeaderComponent, FooterComponent),
    importProvidersFrom(AuthGuard, HttpClientModule, SharedModule),
    importProvidersFrom(MatDialog, MatPaginator, MatProgressSpinner, MatSort, MatTable, MatInput),
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntl },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    AuthenticationService,
    OptionsService,
    AuthGuard,
    RequestCacheService
  ]
}).catch(err => console.error(err));
