export module ServerModels {
    export class ReleaseNotesOutput {
        Data: string;
        Format: ReleaseNotesOutputFormat;
    }

    export enum ReleaseNotesOutputFormat {
        Markdown,
        XWiki,
        Wiki,
        Html
    }

    export interface Action<T> {
        (arg: T): void;
    }

    export class ReleaseNotesGenerationOptions {
        ClosedOnly: boolean;
	    AllCodeSnippets: boolean;
        UseLinksToIssueTracker: boolean;
        Format: ServerModels.ReleaseNotesOutputFormat;
    }

    export class PageableResult<T> {
        Params: PageableParams;
        Data: T[];
        TotalCount: number;
    }

    export class PageableParams {
        Filter: string;
        Sorts: SortingInfo[];
        PageNumber: number;
        PageSize: number;
    }

    export class SortingInfo {
        SortField: string;
        SortOrder: string;
        Order: number;
    }

    export class Server {
        Id: number;
        Url: string;
    }

    export class Project {
        Id: number;
        Name: string;
        Identifier: string;
        Description: string;
    }

    export class Version {
        Id: number;
        Name: string;
        Identifier: string;
        Description: string;
        Status: string;
    }

    export class UserDto {
        id: string;
        userName: string;
        email: string;
        password: string;
        passwordHash: string;
        jobTitle: string;
        friendlyName: string;
        fullName: string;
        configuration: string;
        isEnabled: boolean;
        isLockedOut: boolean;
        createdBy: string;
        updatedBy: string;
        createdOn?: Date;
        updatedOn?: Date;
    }

    export class Issue {
        Id: string;
        IssueNumber: string;
        ProjectKey: string;
        Key: string;
        Project: string;
        FixedVersion: string;
        Status: string;
        Tracker: string;
        Subject: string;
        Description: string;
    }
}
