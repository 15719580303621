import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { error } from 'selenium-webdriver';
import { retry, catchError } from 'rxjs/operators';
import { ApiDataService } from '@app/core/services/api-data.service';
import { ServerModels } from '@app/core/models/ServerModels';
import { Observable, of } from 'rxjs';

@Component({
	standalone: true,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	commitHash: string;
	currentYear: string;

    constructor(private dataService: ApiDataService) {
		this.currentYear = new Date().getFullYear().toString();
    }

    ngOnInit() {
	    var commitHashObservable = this.dataService
		    .getCommitHash()
		    .pipe(
			    catchError((err: any, caught: Observable<any>) => {
				    return of("<no connection to server>");
			    })
		    )
		    .subscribe(data => {
			    this.commitHash = data;
				if (commitHashObservable) {
					commitHashObservable.unsubscribe();
				}
		    });
    }
}
