import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

const maxAge = 30000;
@Injectable({ providedIn: "root" })
export class RequestCacheService {

    cache = new Map();

    get(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const url = req.urlWithParams;
        const cached = this.cache.get(url);
        console.info(`cache (${url}) - ` + cached);

        if (!cached) {
            return undefined;
        }

        const isExpired = cached.createdOn < (Date.now() - maxAge);
        if (isExpired) {
            return undefined;
        }

        console.info("cache hit - " + req.urlWithParams);
        return cached.response;
    }

    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.url;
        const entry = { url, response, createdOn: Date.now() };
        this.cache.set(url, entry);
        console.log(url + " - cached")

        const expired = Date.now() - maxAge;
        this.cache.forEach(expiredEntry => {
            if (expiredEntry.createdOn < expired) {
                this.cache.delete(expiredEntry.url);
            }
        });
    }
}