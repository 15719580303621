// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#header {
  width: 100%;
  background: #5d5d5d;
  padding: 15px;
  color: #ffffff;
}
div#header a {
  color: #ffffff;
  text-decoration: underline;
  margin-left: 7px;
}`, "",{"version":3,"sources":["webpack://./app/core/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;AACJ;AACI;EACI,cAAA;EACA,0BAAA;EACA,gBAAA;AACR","sourcesContent":["div#header {\r\n    width: 100%;\r\n    background: #5d5d5d;\r\n    padding: 15px;\r\n    color: #ffffff;\r\n\r\n    a {\r\n        color: #ffffff;\r\n        text-decoration: underline;\r\n        margin-left: 7px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
