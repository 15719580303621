import { Injectable, EventEmitter, NgZone } from '@angular/core';
//import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ServerModels } from '@app/core/models/ServerModels';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

    tokenKey = "token_key";
    token = null;

    constructor(private ngZone: NgZone, private router: Router, private http: HttpClient) {
        console.log("new auth service");
    }

    //onAuthenticationChanged(isAuthenticated: boolean) {
    //    console.log("authentication: " + isAuthenticated);
    //    this.authenticationChanged.next(isAuthenticated);
    //}

    login(userName: string, password: string, server: string): Promise<string> {
        var userDto = new ServerModels.UserDto();
        Object.assign(userDto, { userName, password, server });

        return new Promise<string>((resolve, reject) => {
            this.http.post(environment.baseUrl + "/api/auth/login", userDto)
                .subscribe(
                    token => {
                        this.setToken(token);
                        resolve((<any>token).userName);
                    },
                    error => {
                        console.log(error);
                        reject(error);
                    });
            
            //this.onAuthenticationChanged(true);
            
        });
    }

    logout() {
        this.removeToken();
        this.router.navigate(['/login']);
        //this.onAuthenticationChanged(false);
    }

    getToken() {
        var storageItem = localStorage.getItem(this.tokenKey);
        //console.log("token accessed: " + storageItem);
        if (!!!storageItem) {
            return null;
        }

        var token = JSON.parse(storageItem);
        
        var accessToken = token["AccessToken"];
        if (accessToken != this.token) {
            this.token = token["AccessToken"];
            this.ngZone.run(() => this.isAuthenticatedSubject.next(!!this.token));
        }

        return token;
    }

    setToken(token) {
        localStorage.setItem(this.tokenKey, JSON.stringify(token));
        this.ngZone.run(() => this.isAuthenticatedSubject.next(!!token["AccessToken"]));
    }

    getAccessToken() {
        var token = this.getToken();
        if (!!!token) {
            return null;
        }

        var accessToken = token['AccessToken'];
        return accessToken;
    }

    isAuthenticated() {
        let token = this.getAccessToken();
        console.log("is authed: " + token);
        if (token) {
            return true;
        }
        else {
            return false;
        }
    }

    refreshToken() {    
    }

    removeToken() {
        this.ngZone.run(() => this.isAuthenticatedSubject.next(false));
        localStorage.removeItem(this.tokenKey);
        //this.onAuthenticationChanged(false);
    }
}
