import { Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { 
    Router, RouterModule, RouterEvent, provideRouter, Routes,
    NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoadingSpinnerService } from '@app/shared/services/loading-spinner.service';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { NotFoundComponent } from '@app/core/not-found/not-found.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, AsyncPipe, HeaderComponent, FooterComponent, NgIf]
})
export class AppComponent implements OnInit {
    // Sets initial value to true to show loading spinner on first load
    loading = true;

    constructor(
        private router: Router,
        public spinnerService: LoadingSpinnerService,
        public authenticationService: AuthenticationService/*is used on view*/) {
        router.events.subscribe((event) => {
            this.navigationInterceptor(event);
        });
    }

    ngOnInit() {
        console.log('Inside app.component');
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event): void {
        if (event instanceof NavigationStart) {
            this.spinnerService.registerOperation(event.url);
        }
        else if (event instanceof NavigationEnd) {
            this.spinnerService.unregisterOperation(event.url);
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        else if (event instanceof NavigationCancel) {
            this.spinnerService.unregisterOperation(event.url);
        }
        else if (event instanceof NavigationError) {
            this.spinnerService.unregisterOperation(event.url);
        }
        else {
            //console.info("Event " + event.constructor.name);
        }
    }
}
