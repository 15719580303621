import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authentication: AuthenticationService, private router: Router) {
    }

    canActivate(
            next: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let token = this.authentication.getToken();
        let accessToken = this.authentication.getAccessToken();

        if (!token) {
            console.error("User is not authenticated.");
            this.redirectToLoginPage();
            return false;
        }
        else if (this.authentication.isAuthenticated()) {
            return true;
        }
        else {
            this.authentication.refreshToken();
            return true;
        }
    }

    redirectToLoginPage() {
        this.router.navigate(['/login']);
    }
}
