import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler,
    HttpRequest, HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import { Observable, EMPTY } from 'rxjs';
import { catchError, retry, tap,  } from 'rxjs/operators';
import { LoadingSpinnerService } from '../services/loading-spinner.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    
    constructor(private spinnerService: LoadingSpinnerService,
        private authService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.registerOperation(request.urlWithParams);

        return next
            .handle(request)
            .pipe(
                //retry(1),
                catchError((err: HttpErrorResponse) => {
                    this.spinnerService.unregisterOperation(err.url);

                    if (err.error instanceof Error) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', err.error.message);
                    } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                    }

                    if (err.status == 401) {
                        this.authService.logout();
                        return EMPTY;
                    }

                    //throw err.error;
                    throw err;

                    // ...optionally return a default fallback value so app can continue (pick one)
                    // which could be a default value (which has to be a HttpResponse here)
                    // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
                    // or simply an empty observable
                    //return EMPTY;
                }),
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.spinnerService.unregisterOperation(event.url);

                        if (~~(event.status / 100) > 3) {
                            console.info('HttpResponse::event =', event, ';');
                        }
                    }
                }));
    }
}