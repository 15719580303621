import { Injectable } from '@angular/core';
//import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable, EMPTY } from 'rxjs';
//import { throw } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpResponse, HttpParams, HttpHeaders } from "@angular/common/http";
import { retry, catchError, map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '@app/shared/services/authentication.service';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ServerModels } from '@app/core/models/ServerModels';

import { environment } from 'environments/environment';

@Injectable()
export class DataService {
    //baseUrl = 'http://localhost';
    //headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private httpClient: HttpClient, private authService: AuthenticationService) {
    }

    // notice the <T>, making the method generic
    post<T>(url: string, data: any, pageableCallback?: ServerModels.Action<ServerModels.PageableResult<T>>, params?: HttpParams): Observable<T> {
        //var headersWithAuth = <HttpHeaders>Object.create(this.headers);
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
                            .set("Authorization", "Bearer " + this.authService.getAccessToken());
        //console.log("auth header: " + headersWithAuth);

        return this.httpClient
            .post(environment.baseUrl + url, data, {
                headers: headers,
                observe: 'response',
                responseType: 'json',
                params: params,
                
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (pageableCallback) {
                        pageableCallback(<ServerModels.PageableResult<T>>response.body);
                        //console.log(response.body.Data);
                        return response.body.Data;
                    }
                    else {
                        return response.body;
                    }
                }),
                //retry(1), // optionally add the retry
                catchError((err: HttpErrorResponse) => {
                    if (pageableCallback) {
                        pageableCallback(new ServerModels.PageableResult<T>());
                    }

                    if (err.error instanceof Error) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', err.error.message);
                    } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                    }

                    // ...optionally return a default fallback value so app can continue (pick one)
                    // which could be a default value
                    // return Observable.of<any>({my: "default value..."});
                    // or simply an empty observable
                    return EMPTY;
                }));
    }

    // notice the <T>, making the method generic
    get<T>(url: string, pageableCallback?: ServerModels.Action<ServerModels.PageableResult<T>>, params?: HttpParams): Observable<T> {
        //var headersWithAuth = <HttpHeaders>Object.create(this.headers);
        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set("Authorization", "Bearer " + this.authService.getAccessToken());
        //console.log("auth header: " + headersWithAuth);

        return this.httpClient
            .get(environment.baseUrl + url, {
                headers: headers,
                observe: 'response',
                responseType: 'json',
                params: params
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (pageableCallback) {
                        pageableCallback(<ServerModels.PageableResult<T>>response.body);
                        //console.log(response.body.Data);
                        return response.body.Data;
                    }
                    else {
                        return response.body;
                    }
                }),
                //retry(1), // optionally add the retry
                catchError((err: HttpErrorResponse) => {
                    if (pageableCallback) {
                        pageableCallback(new ServerModels.PageableResult<T>());
                    }

                    if (err.error instanceof Error) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', err.error.message);
                    } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong,
                        console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
                    }

                    // ...optionally return a default fallback value so app can continue (pick one)
                    // which could be a default value
                    // return Observable.of<any>({my: "default value..."});
                    // or simply an empty observable
                    return EMPTY;
                }));
    }
}