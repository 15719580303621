import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ServerModels } from '@app/core/models/ServerModels';
import { throwError, Observable } from 'rxjs';
import { retry, catchError, switchMap, map } from 'rxjs/operators';
import { DataService } from '@app/shared/services/data.service';
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class ApiDataService extends DataService implements OnInit {
    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }

    constructor(http: HttpClient, authService: AuthenticationService) {
        super(http, authService);
    }

    getServers(): Observable<ServerModels.Server[]> {
        return this.get<ServerModels.Server[]>("/api/servers/all");
    }

    getProjects(): Observable<ServerModels.Project[]> {
        return this.get<ServerModels.Project[]>("/api/projects/all");
    }

    getVersions(project: ServerModels.Project): Observable<ServerModels.Version[]> {
        return this.get<ServerModels.Version[]>("/api/versions/for/" + project.Id);
    }

    generateReleaseNotes(
        project: ServerModels.Project,
        version: ServerModels.Version,
        params: ServerModels.ReleaseNotesGenerationOptions): Observable<ServerModels.ReleaseNotesOutput> {
        return this.get<ServerModels.ReleaseNotesOutput>(
            "/api/notes/generate/" + project.Id + "/" + version.Id,
            null,
            new HttpParams()
                .append("ClosedOnly", params.ClosedOnly.toString())
                .append("UseLinksToIssueTracker", params.UseLinksToIssueTracker.toString())
	            .append("Format", params.Format.toString())
	            .append("AllCodeSnippets", params.AllCodeSnippets.toString())
	        );
    }

    getIssues(
        project: ServerModels.Project,
        version: ServerModels.Version,
        forcedRefresh: boolean,
        filter = '',
        sortField = 'key',
        sortOrder = 'asc',
        pageNumber = 0,
        pageSize = 50,
        pageableCallback?: ServerModels.Action<ServerModels.PageableResult<ServerModels.Issue[]>>)
            : Observable<ServerModels.Issue[]> {
        var data = this.post<ServerModels.Issue[]>(
            "/api/issues/for/" + project.Id + "/" + version.Id,
            {
                filter,
                forcedRefresh,
                sorts: [
                    {
                        sortField: 'tracker',
                        sortOrder: 'asc',
                        order: 0
                    },
                    {
                        sortField,
                        sortOrder,
                        order: 1
                    }
                ],
                pageNumber,
                pageSize
            },
            pageableCallback);

        return data;
    }

    getCommitHash(): Observable<string> {
	    return this.get<string>("/api/info/commitHash");
    }
}
