import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {
    registeredOperationsCount: number = 0;
    private isBusySource = new BehaviorSubject<boolean>(false);
    public isBusy = this.isBusySource.asObservable();

    unregisterOperation(url: string) {
        console.log("http request UNregistering - " + url);
        this.registeredOperationsCount--;
        if (this.registeredOperationsCount <= 0) {
            this.registeredOperationsCount = 0;
            this.isBusySource.next(false);
        }
    }
    registerOperation(url: string) {
        console.log("http request registering - " + url);
        this.registeredOperationsCount++;
        if (this.registeredOperationsCount == 1) {
            this.isBusySource.next(true);
        }
    }
}
